/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useDeviceType } from './useDeviceType';

export function useIsBot() {
  const deviceType = useDeviceType();
  return deviceType === 'bot';
}
