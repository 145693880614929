/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import {
  type TopNavProductDataResultsModel,
  type TopNavProductDataResultsResponse,
} from '../interfaceV2';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { requestBaseURLGCPExtOrInt } from '@/config/serviceAPI';
import { type AxiosInstance } from 'axios';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useSegments } from '@/features/header/api/getSegments';
import { useAcesFlags } from '@/features/ymme/utils/acesFlags';

const PRODUCT_DATA_URL = `${requestBaseURLGCPExtOrInt}/sls/pd/product-navigation-search/v1/browse-categories`;

export type NavigationMenusOptions = {
  locale: Locale;
  preview: boolean;
  categoryIdPath?: string;
  storeId?: number;
  year?: number;
  makeId?: number;
  modelId?: number;
  vehicleTypeId?: number;
  makeModelYearPath?: string;
  vehicleQuestions?: string;
  userSegments?: string;
};

const getProductDataResults = async (
  options: NavigationMenusOptions,
  axiosInstance?: AxiosInstance
) => {
  const {
    locale,
    preview,
    categoryIdPath,
    storeId,
    year,
    makeId,
    modelId,
    vehicleTypeId,
    makeModelYearPath,
    vehicleQuestions,
    userSegments,
  } = options;

  const country = getCountryFromLocale(locale);

  const customerType = 'B2C';
  const salesChannel = 'ECOMM';
  const recordType = 'APP';

  const response = await getAxios(axiosInstance).get<TopNavProductDataResultsResponse>(
    PRODUCT_DATA_URL,
    {
      params: {
        customerType,
        salesChannel,
        country,
        preview,
        categoryIdPath,
        storeId,
        year,
        makeId,
        modelId,
        vehicleTypeId,
        makeModelYearPath,
        vehicleQuestions,
        recordType,
        userSegments,
      },
      withCredentials: false,
    }
  );
  return topNavDataSelector(response.data);
};

const {
  useData,
  prefetch: prefetchProductDataTopNavMenuV2,
  getFromCache: getProductDataTopNavMenuFromCache,
} = createQuery<TopNavProductDataResultsModel, NavigationMenusOptions>(
  'topNavProductData',
  getProductDataResults
);

export const topNavDataSelector = (
  data: TopNavProductDataResultsResponse
): TopNavProductDataResultsModel => {
  const rootCategories =
    data.categoryResults?.map((item) => ({
      topNavServletPath: item.taxonomyPath,
      displayName: item.categoryName ?? '',
      text: item.categoryName ?? '',
      catName: item.categoryName ?? '',
      seoUrl: item.canonicalUrl || item.taxonomyPath,
      parentUrlState: item.canonicalUrl ?? '',
      childCategories:
        item.childCategories?.map((childCategory) => ({
          label: childCategory.categoryName,
          seoUrl: childCategory.canonicalUrl,
          canonicalPath: childCategory.canonicalUrl,
          refPageType: childCategory.pageType,
          partGroupId: childCategory.partGroupId,
          taxonomyPath: childCategory.taxonomyPath,
          categoryIdPath: childCategory.categoryIdPath,
          childCategories: childCategory.childCategories,
        })) ?? [],
      popularCategories:
        item.popularParts?.map((popularCategory) => ({
          label: popularCategory.displayName ?? '',
          seoUrl: popularCategory.canonicalUrl,
          canonicalPath: popularCategory.canonicalUrl,
          refPageType: popularCategory.pageType ?? '',
          partGroupId: popularCategory.partGroupId,
          categoryIdPath: popularCategory.categoryIdPath,
          taxonomyPath: popularCategory.taxonomyPath ?? '',
        })) ?? [],
    })) ?? [];

  return {
    rootCategories,
  };
};

type UseTopNavSubCategoriesOptions = {
  isNavigatingBack?: boolean;
  level: number;
  categoryIdPath?: string;
};

export const useProductDataTopNavMenuV2 = (options?: UseTopNavSubCategoriesOptions) => {
  const { isNavigatingBack, level = 0, categoryIdPath = '' } = options || {};

  const locale = useLocale();
  const { acesEnabled, acesNonVehicleEndpointsEnabled } = useAcesFlags();
  const preferredVehicle = usePreferredVehicle();
  const { data: storeDetailsData } = useStoreDetailsData();
  const { data: segments } = useSegments();

  const enableQueryCall = level > 1 || isNavigatingBack || level === -1;
  return useData({
    locale,
    enabled: acesNonVehicleEndpointsEnabled && acesEnabled && enableQueryCall,
    preview: showXMPreviewDate(),
    categoryIdPath,
    storeId: Number(storeDetailsData?.storeNumber) || undefined,
    year: Number(preferredVehicle?.year) || undefined,
    makeId: Number(preferredVehicle?.makeId) || undefined,
    modelId: Number(preferredVehicle?.modelId) || undefined,
    vehicleTypeId: Number(preferredVehicle?.vehicleTypeId) || undefined,
    makeModelYearPath:
      preferredVehicle?.make && preferredVehicle?.model
        ? `${preferredVehicle?.make}/${preferredVehicle?.model}`
        : undefined,
    vehicleQuestions: undefined,
    userSegments: segments?.join(',') || undefined,
    staleTime: 30 * 60000,
  });
};

export { prefetchProductDataTopNavMenuV2, getProductDataTopNavMenuFromCache };
