/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackLink } from '@/features/contentstack/interface';
import type { PreFooterColumnContent } from '../../interface';

export const getLinkHref = (content: PreFooterColumnContent | ContentStackLink) =>
  'CTALink' in content ? content.CTALink : content.href;

export const getLinkTitle = (content: PreFooterColumnContent | ContentStackLink) =>
  'CTALink' in content ? content.name : content.title;
