/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useMemo, useEffect } from 'react';
import type { PreFooterColumnContent } from '../interface';
import type { ContentStackLink } from '@/features/contentstack/interface';
import { useIsBot } from '@/utils/useIsBot';

type UseExpandableLinksOptions = {
  isMobile?: boolean;
  minLinks?: number;
  maxLinks?: number;
};

type BaseExpandableLinks = {
  isExpanded: boolean;
  visibleLinks: PreFooterColumnContent[] | ContentStackLink[];
  toggleExpanded: () => void;
  shouldFocus: boolean;
};

type DesktopExpandableLinks = BaseExpandableLinks;

type MobileExpandableLinks = BaseExpandableLinks & {
  isColumnExpanded: boolean;
  toggleColumnExpanded: () => void;
};

export const MIN_LINKS = 5;

export const MAX_LINKS = 10;

export function useExpandableLinks<T extends UseExpandableLinksOptions>(
  links: PreFooterColumnContent[] | ContentStackLink[],
  options: T = {} as T
): T['isMobile'] extends true ? MobileExpandableLinks : DesktopExpandableLinks {
  const isBot = useIsBot();
  const { isMobile = false, minLinks = MIN_LINKS, maxLinks = MAX_LINKS } = options;

  const [isExpanded, setIsExpanded] = useState(true);
  const [isColumnExpanded, setIsColumnExpanded] = useState(true);
  const [shouldFocus, setShouldFocus] = useState(false);

  // Reset to collapsed state after initial render for JS users
  useEffect(() => {
    if (isBot) {
      return;
    }

    setIsExpanded(false);
    if (isMobile) {
      setIsColumnExpanded(false);
    }
  }, [isBot, isMobile]);

  const visibleLinks = useMemo(() => {
    const limit = isExpanded ? Math.min(links.length, maxLinks) : Math.min(links.length, minLinks);
    return links.slice(0, limit);
  }, [links, isExpanded, maxLinks, minLinks]);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
    setShouldFocus((prev) => !prev);
  };
  const toggleColumnExpanded = () => setIsColumnExpanded((prev) => !prev);

  const baseReturn: BaseExpandableLinks = {
    isExpanded,
    visibleLinks,
    toggleExpanded,
    shouldFocus,
  };

  if (isMobile) {
    return {
      ...baseReturn,
      isColumnExpanded,
      toggleColumnExpanded,
    } as T['isMobile'] extends true ? MobileExpandableLinks : DesktopExpandableLinks;
  }

  return baseReturn as T['isMobile'] extends true ? MobileExpandableLinks : DesktopExpandableLinks;
}
