/**
 * Copyright 2020-2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type ComponentType, useEffect, useState } from 'react';
import { SmartLink as Link, type SmartLinkProps } from '@/utils/smartLink';

type PreFooterLinkProps = {
  href: string;
  title: string;
  className?: string;
  isLinkToFocus?: boolean;
  isAutoFocus?: boolean;
  LinkComponent?: ComponentType<SmartLinkProps>;
};

export const PreFooterLink = ({
  href,
  title,
  className,
  isLinkToFocus,
  isAutoFocus,
  LinkComponent = Link,
}: PreFooterLinkProps) => {
  const [shouldFocusTop, setShouldFocusTop] = useState(false);

  useEffect(() => {
    if (shouldFocusTop) {
      const azLogoFixed = document.getElementById('azLogoLinkFixed');
      azLogoFixed?.focus();
      setShouldFocusTop(false);
    }
  }, [shouldFocusTop]);

  return (
    <LinkComponent
      to={href}
      className={className}
      onClick={() => {
        setShouldFocusTop(true);
      }}
      setFocusToAnchorTag={isAutoFocus && isLinkToFocus}
    >
      <span>{title}</span>
    </LinkComponent>
  );
};
