/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FormattedContentStackHomepage } from '@/features/contentstack/interface';
import { CMSBanner } from '@/features/contentstack/components/CMSBanner/CMSBanner';
import { CMSButton } from '@/features/contentstack/components/CMSButton/CMSButton';
import { CMSCertona } from '@/features/contentstack/components/CMSCertona/CMSCertona';
import { CMSGrid } from '@/features/contentstack/components/CMSGrid/CMSGrid';
import { CMSBannerSplit } from '@/features/contentstack/components/CMSBannerSplit/CMSBannerSplit';
import { CMSFeaturedSpotlight } from '@/features/contentstack/components/CMSFeaturedSpotlight/CMSFeaturedSpotlight';
import { CMSGridBanner } from '@/features/contentstack/components/CMSGridBanner/CMSGridBanner';
import { CMSCitrus } from '@/features/contentstack/components/CMSCitrus/CMSCitrus';
import { CMSCitrusCarousel } from '@/features/contentstack/components/CMSCitrusCarousel/CMSCitrusCarousel';
import { CMSTarget } from '@/features/contentstack/components/CMSTarget/CMSTarget';
import { CMSExperienceInjector } from '@/features/contentstack/components/CMSExperienceInjector/CMSExperienceInjector';
import type { CertonaBlockProps, CertonaRecommendation } from '@/features/certona';
import { CMSPreFooter } from '@/features/contentstack/components/CMSPreFooter/CMSPreFooter';
import React from 'react';
import { useRef } from 'react';
import { HomepageHero } from '../HomepageHero/HomepageHero';
import { EmailSignup } from '@/components/ContentBlocks/EmailSignup';
import { CMSHomeSpacer } from '../CMSHomeSpacer';
import styles from './CMSModularHandler.module.scss';
import { STARCTypography } from '@/components/STARCTypography';
import { useCitrusHomepageData } from '@/features/citrus/api/getCitrusHomepageData';
import { SuperDuperHero } from '../SuperDuperHero/SuperDuperHero';
import { OptionalSuspense } from '@/features/performance/components/OptionalSuspense';
import { CMSYmme } from '@/features/contentstack/components/CMSYmme/CMSYmme';

type Props = {
  certonaRecommendations: CertonaRecommendation[];
  content: FormattedContentStackHomepage['homepageBody'];
  contentSeo: FormattedContentStackHomepage['seoTags'];
  setCertonaRecommendationAddedToCart?: React.Dispatch<React.SetStateAction<boolean>>;
} & CertonaBlockProps;

function CMSCitrusSpacer({ children }: { children: React.ReactNode }) {
  return <CMSHomeSpacer shouldIgnoreCarouselSpacing>{children}</CMSHomeSpacer>;
}

export const CMSModularHandler = ({
  content,
  contentSeo,
  certonaRecommendations,
  setCertonaRecommendationAddedToCart,
  ...certonaProps
}: Props) => {
  const heroComponentIndex = useRef<number | null>(null);
  const superDuperHeroIndex = useRef<number | null>(null);
  const { data: citrusAds } = useCitrusHomepageData({
    enabled: content?.some((block) => !!block.citrus_ad?.citrus_ad?.[0]),
  });

  return (
    <STARCTypography>
      <span className="sr-only">
        <h1>{contentSeo?.page_heading || 'Auto Parts at AutoZone'}</h1>
      </span>
      {content?.map((modularBlock, i) => {
        const shouldReduceSpacing = i === 0;
        const customSpacingRequired =
          heroComponentIndex.current !== null && heroComponentIndex.current + 1 === i;
        const customClass = customSpacingRequired ? styles.customSpacer : undefined;

        if (modularBlock.button) {
          return <CMSButton options={modularBlock.button} key={i} />;
        }
        if (modularBlock.ymme?.ymme?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSYmme
                  content={modularBlock.ymme.ymme[0]}
                  nonShopPage={true}
                  removeMargins
                  removeBottomMargin
                  withPadding={false}
                />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.banner?.banner?.[0]?.asset_size) {
          const heroIndex = heroComponentIndex.current ?? superDuperHeroIndex.current ?? -1;
          const isBeforeHero = heroIndex !== -1 && i < heroIndex;

          const loading = isBeforeHero ? 'eager' : undefined;

          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer shouldReduceSpacing={shouldReduceSpacing} className={customClass}>
                <CMSBanner content={modularBlock.banner.banner[0]} loading={loading} />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.certona?.certona?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer shouldIgnoreCarouselSpacing className={customClass}>
                <CMSCertona
                  content={modularBlock.certona.certona[0]}
                  certonaRecommendations={certonaRecommendations}
                  onCertonaAddToCartClick={() => {
                    setCertonaRecommendationAddedToCart?.(true);
                  }}
                  {...certonaProps}
                />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.parttype_grid?.parttype_grid?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSGrid
                  content={modularBlock.parttype_grid.parttype_grid[0]}
                  headlineOptions={{ underline: true }}
                />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.banner_split?.banner_split?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSBannerSplit
                  content={modularBlock.banner_split.banner_split[0]}
                  headlineOptions={{ underline: true }}
                />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.featured_spotlight?.feature_spotlight?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSFeaturedSpotlight
                  content={modularBlock.featured_spotlight.feature_spotlight[0]}
                />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.target_div?.target_div?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSTarget content={modularBlock.target_div.target_div[0]} />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.experience_injector?.experience_injector?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSExperienceInjector
                  content={modularBlock.experience_injector.experience_injector[0]}
                />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.grid_banner?.grid_banner?.[0]) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSGridBanner content={modularBlock.grid_banner.grid_banner[0]} />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.hero_component?.hero_component?.[0]) {
          heroComponentIndex.current = i;

          return (
            <OptionalSuspense key={`${i}-hero`}>
              <CMSHomeSpacer removeHorizontalSpacing>
                <HomepageHero options={modularBlock.hero_component.hero_component[0]} />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.super_duper_hero?.super_duper_hero?.[0]) {
          const cardData = content.find((block) => 'horizontal_scroll_cards' in block)
            ?.horizontal_scroll_cards?.horizontal_scroll_cards?.[0]?.card_content;
          superDuperHeroIndex.current = i;

          return (
            <OptionalSuspense key={`${i}-hero`}>
              <CMSHomeSpacer removeHorizontalSpacing removeVerticalSpacing>
                <SuperDuperHero
                  options={modularBlock.super_duper_hero.super_duper_hero[0]}
                  horizontalCards={cardData}
                />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.prefooter) {
          return (
            <OptionalSuspense key={i}>
              <CMSHomeSpacer className={customClass}>
                <CMSPreFooter content={modularBlock.prefooter.prefooter?.[0]?.column} />
              </CMSHomeSpacer>
            </OptionalSuspense>
          );
        }

        if (modularBlock.email_block) {
          return (
            <OptionalSuspense key={i}>
              <EmailSignup />
            </OptionalSuspense>
          );
        }

        if (modularBlock.citrus_ad?.citrus_ad?.[0]) {
          return (
            <OptionalSuspense key={i}>
              {modularBlock.citrus_ad.citrus_ad[0].type === 'carousel' ? (
                <CMSHomeSpacer shouldIgnoreCarouselSpacing>
                  <CMSCitrusCarousel />
                </CMSHomeSpacer>
              ) : (
                <CMSCitrus
                  content={modularBlock.citrus_ad.citrus_ad[0]}
                  citrusAds={citrusAds?.ads}
                  SpacerComponent={CMSCitrusSpacer}
                />
              )}
            </OptionalSuspense>
          );
        }

        return null;
      })}
    </STARCTypography>
  );
};
