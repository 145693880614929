/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Component } from 'react';
import QuickSubscription from '../../../components/ContentBlocks/QuickSubscription';
import { Label } from '@/features/i18n';
import { crashReporter } from '../../../utils/crashReporter';
import { EMAIL } from '@/utils/validatorRegex';
import azURL from './../../../config/serviceAPI';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { getAxiosForClient } from '@/lib/axios';

const trackNewsLetterSubscribeHome = (email: string) => {
  clickTrack({
    emailaddress: email,
    eventType: 'newsletterSignupHome',
  });
};

type Props = {};

type State = {
  email: string;
  lblError: any;
  errorEmail: boolean;
  signUpSuccess: boolean;
};

export class EmailSignup extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      lblError: '',
      errorEmail: false,
      signUpSuccess: false,
    };
  }

  validateEmail: () => void = () => {
    const lblPleaseEnterAValidEmailId = (
      <Label label="label_MyAccountMyProfile_editProfile_PleaseEnterAValidEmailId" />
    );
    let lblError: React.ReactElement | string = '';
    let errorEmail = false;

    if (!EMAIL.test(this.state.email) && lblPleaseEnterAValidEmailId) {
      lblError = lblPleaseEnterAValidEmailId;
      errorEmail = true;
    } else {
      this.signUp(this.state.email);
    }

    this.setState({
      lblError,
      errorEmail,
    });
  };
  handleEmailChange: (event: any) => void = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };
  signUp: (email: string) => void = (email: string) => {
    const emailId = email;

    getAxiosForClient()
      .post(azURL('quickSubscribe'), {
        email: this.state.email,
      })
      .then(({ data }) => {
        this.setState({
          email: '',
          signUpSuccess: data.success,
        });
        trackNewsLetterSubscribeHome(emailId);
      })
      .catch((err) => {
        this.setState({
          lblError: <Label label="label_MyAccountMyProfile_editProfile_PleaseEnterAValidEmailId" />,
          errorEmail: true,
        });
        crashReporter('An error occurred in the quickSubscribe block', '', err);
      });
  };

  render() {
    return (
      <QuickSubscription
        validateEmail={this.validateEmail}
        handleEmailChange={this.handleEmailChange}
        email={this.state.email}
        lblError={this.state.lblError}
        errorEmail={this.state.errorEmail}
        signUpSuccess={this.state.signUpSuccess}
      />
    );
  }
}
