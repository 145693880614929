/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { useLabels } from '@/hooks/useLabels';
import { Button } from '@/components/Button';
import { PreFooterLink } from './PreFooterLink';
import { useExpandableLinks, MIN_LINKS } from '../../hooks/useExpandableLinks';
import { getLinkTitle, getLinkHref } from './utils';
import type { PreFooterLinkColumnProps } from '../../interface';
import styles from './styles.module.scss';

const labelsMap = {
  showLess: 'label_Show_Less',
  showAll: 'label_Show_All',
};

export function DesktopLinkColumn({
  title,
  links,
  className,
  LinkComponent,
}: PreFooterLinkColumnProps) {
  const labels = useLabels(labelsMap);

  const { isExpanded, visibleLinks, shouldFocus, toggleExpanded } = useExpandableLinks(links);

  const ariaLabel = isExpanded ? `${labels.showLess} ${title}` : `${labels.showAll} ${title}`;

  return (
    <div className={cx(styles.linkColumnContainer, className)}>
      <h2 className={styles.linkColumnTitle}>{title}</h2>
      <ul>
        {visibleLinks.map((content, index) => (
          <li className={styles.linkContainer} key={getLinkHref(content)}>
            <PreFooterLink
              href={getLinkHref(content)}
              title={getLinkTitle(content)}
              isLinkToFocus={index === MIN_LINKS}
              className={styles.linkItem}
              isAutoFocus={shouldFocus}
              LinkComponent={LinkComponent}
            />
          </li>
        ))}
      </ul>
      {links.length > MIN_LINKS && (
        <Button
          data-testid="show-button"
          onClick={toggleExpanded}
          variant="ghostPureText"
          customClass={styles.allOrLess}
          ariaLabel={ariaLabel}
          ariaExpanded={isExpanded}
        >
          {isExpanded ? labels.showLess : labels.showAll}
        </Button>
      )}
    </div>
  );
}
