/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { dehydrate } from '@tanstack/react-query';
import { HomePage } from '@/features/home/pages/HomePage';
import { prefetchHomePageContent } from '@/features/home/api/getHomePageContentV2';
import { type PageContextType } from '@/next-types';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { configureSharedPageData } from '@/utils/configureSharedPageData.server';

export const config = {
  unstable_JsPreload: false,
};

function Page() {
  return <HomePage />;
}

Page.getInitialProps = async (context: PageContextType) => {
  if (__IS_SERVER__) {
    const { queryClient, initialAppState } = await configureSharedPageData(context);
    const headerData = getHeaderDataFromCache(queryClient);

    const dataToFetch = [
      prefetchHomePageContent(queryClient, context, headerData?.segments?.sort() ?? []),
    ];

    await Promise.allSettled(dataToFetch);

    return {
      initialAppState,
      dehydratedState: dehydrate(queryClient),
    };
  }

  return {};
};

export default Page;
