/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { KeyboardEvent } from 'react';
import cx from 'classnames';
import type { PreFooterLinkColumnProps } from '../../interface';
import { PreFooterLink } from './PreFooterLink';
import styles from './styles.module.scss';
import { Button } from '@/components/Button';
import NextImage from '@/components/NextImage';
import { useLabels } from '@/hooks/useLabels';
import usePrevious from '@/utils/usePrevious';
import { eventConstants } from '@/constants/event';
import { useExpandableLinks, MIN_LINKS } from '../../hooks/useExpandableLinks';
import { getLinkHref, getLinkTitle } from './utils';

const CHEVRON_ICON = '/images/footer/footer_down_arrow.svg';

const labelsMap = {
  showLess: 'label_Show_Less',
  showAll: 'label_Show_All',
  expansiblePanel: 'label_ShelfPage_leftSideBar_ExpansiblePanel',
};

export function MobileLinkColumn({
  title,
  links,
  LinkComponent,
  className,
}: PreFooterLinkColumnProps) {
  const labels = useLabels(labelsMap);

  const {
    shouldFocus,
    isExpanded,
    visibleLinks,
    toggleExpanded,
    isColumnExpanded,
    toggleColumnExpanded,
  } = useExpandableLinks(links, { isMobile: true });

  const previousItemToShow = usePrevious(visibleLinks.length);

  const handleColumnKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === eventConstants.enterKeyType || event.key === eventConstants.spaceKeyType) {
      toggleColumnExpanded();
    }
  };

  const ariaLabel = isExpanded ? `${labels.showLess} ${title}` : `${labels.showAll} ${title}`;

  return (
    <div className={cx(styles.linkColumnContainer, className)}>
      <div
        className={styles.linksColumnTitleContainer}
        onKeyPress={handleColumnKeyPress}
        onClick={toggleColumnExpanded}
        role="presentation"
        tabIndex={-1}
      >
        <h2 className={styles.linkColumnTitle}>{title}</h2>
        <NextImage
          tabIndex={0}
          role="button"
          width={24}
          height={12}
          aria-expanded={isColumnExpanded}
          alt={`${title} ${labels.expansiblePanel}`}
          src={CHEVRON_ICON}
          className={cx(styles.chevron, {
            [styles.chevronUp]: isColumnExpanded,
            [styles.chevronDown]: !isColumnExpanded,
          })}
          loading="lazy"
        />
      </div>
      <div
        data-testid="links_container"
        className={cx(styles.linksContainer, {
          [styles.showLinks]: isColumnExpanded || isExpanded,
          [styles.hideLinks]: !isColumnExpanded,
        })}
      >
        <ul>
          {visibleLinks.map((content, index) => (
            <li key={getLinkHref(content)} className={styles.linkContainer}>
              <PreFooterLink
                href={getLinkHref(content)}
                title={getLinkTitle(content)}
                isLinkToFocus={index === previousItemToShow}
                className={styles.linkItem}
                isAutoFocus={shouldFocus}
                LinkComponent={LinkComponent}
              />
            </li>
          ))}
        </ul>
        {links.length > MIN_LINKS && (
          <Button
            data-testid="show-button"
            onClick={toggleExpanded}
            variant="ghostPureText"
            customClass={styles.allOrLess}
            ariaLabel={ariaLabel}
            ariaExpanded={isExpanded}
          >
            {isExpanded ? labels.showLess : labels.showAll}
          </Button>
        )}
      </div>
      {!isColumnExpanded && <hr className={styles.horizontalLine} />}
    </div>
  );
}
